import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axiosInstance from './api/axiosConfig';  // Usa l'istanza Axios configurata
import { useNavigate } from 'react-router-dom';

const HeaderContainer = styled.header`
  background-color: #f8f8f8;
  padding: 64px 112px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.h1`
  color: #A669F7;
  font-size: 4rem;
  margin: 0;
  white-space: pre-wrap; /* Consente il wrapping del testo */
`;

const SearchBarContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffd700;
  padding: 16px;
  border-radius: 16px 100px 100px 16px;
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.2);
  position: relative;
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px 0 0 10px;
  padding: 10px;
  margin-right: 16px;
`;

const SearchInput = styled.input`
  padding: 10px;
  border: none;
  border-radius: 10px 0 0 10px;
  outline: none;
  width: 200px;
  background-color: #fff;
  border-right: 1px solid #ffd700;
`;

const SearchLocationContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  margin-right: 16px;
`;

const SearchLocationInput = styled.input`
  padding: 10px;
  border: none;
  outline: none;
  width: 200px;
  background-color: #fff;
  border-left: 1px solid #ffd700;
  border-right: 1px solid #ffd700;
`;

const SearchButton = styled.button`
  padding: 16px;
  border: none;
  border-radius: 0 100px 100px 0;
  background-color: #00a651;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const SearchCategoryIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

const SearchLocationIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 24px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HighlightedWord = styled.span`
  color: #F6C427; /* Colore specifico per la parola */
`;

const SuggestionsContainer = styled.ul`
  position: absolute;
  top: 60px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0 0 10px 10px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Header = () => {
  const [assets, setAssets] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance.get('/assets/list/')
      .then(response => {
        // Trasformare l'array di asset in un oggetto mappato dai nomi degli asset
        const assetsMap = response.data.reduce((acc, asset) => {
          acc[asset.name] = asset.file_url;
          return acc;
        }, {});
        setAssets(assetsMap);
      })
      .catch(error => console.error('Error fetching assets:', error));
  }, []);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 2) {
      axiosInstance.get(`/suggerimenti_ricerca?q=${query}`)
        .then(response => {
          setSuggestions(response.data);
        })
        .catch(error => console.error('Error fetching search suggestions:', error));
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion);
    setSuggestions([]);
  };

  const handleSearchSubmit = () => {
    navigate(`/elenco_annunci?q=${searchQuery}`);
  };

  return (
    <HeaderContainer>
      <ContentContainer>
        <Title>La market community{'\n'}degli <HighlightedWord>Studenti</HighlightedWord> </Title> {/* Mandare a capo il testo */}
        <SearchBarContainer>
          <SearchInputContainer>
            <SearchCategoryIcon src={assets.searchCategory} alt="Category" />
            <SearchInput
              placeholder="Sto cercando appunti di ..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </SearchInputContainer>
          <SearchLocationContainer>
            <SearchLocationIcon src={assets.searchLocation} alt="Location" />
            <SearchLocationInput placeholder="Milano; Viale Moscova" />
          </SearchLocationContainer>
          <SearchButton onClick={handleSearchSubmit}>
            <SearchIcon src={assets.searchIcon} alt="Search" />
          </SearchButton>
          {suggestions.length > 0 && (
            <SuggestionsContainer>
              {suggestions.map((suggestion, index) => (
                <SuggestionItem key={index} onClick={() => handleSuggestionClick(suggestion)}>
                  {suggestion}
                </SuggestionItem>
              ))}
            </SuggestionsContainer>
          )}
        </SearchBarContainer>
      </ContentContainer>
      <ImageContainer>
        {assets.imgHeader && <img src={assets.imgHeader} alt="Header" style={{ width: '360px', height: '640px' }} />}
      </ImageContainer>
    </HeaderContainer>
  );
}

export default Header;
