import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PromotionsContainer = styled.section`
  padding: 20px;
  background-color: #ffae42;
  color: white;
  text-align: center;
`;

const SliderTitle = styled.h2`
  color: white;
  margin-bottom: 20px;
  font-size: 4rem;
`;

const Slide = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  text-align: left;
  color: white;
`;

const SlideText = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const TextBlockSlider = styled.p`
font-size: 1.5rem;
`;  

const SlideImage = styled.img`
  flex: 1;
  max-width: 50%;
  border-radius: 10px;
`;

const CustomSlider = styled(Slider)`
  .slick-prev:before,
  .slick-next:before {
    color: white;
  }
`;

const Promotions = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <PromotionsContainer>
      
      <CustomSlider {...settings}>
        <Slide>
          <SlideText>
            <SliderTitle>Cerca quello di cui hai bisogno!</SliderTitle>
            <TextBlockSlider>
              Che tu abbia bisogno degli appunti di statistica, di un frullatore o di un paio di scarpe da corsa. Qui puoi trovarli.
            </TextBlockSlider>
          </SlideText>
          <SlideImage src="/path/to/image1.jpg" alt="Slide 1" />
        </Slide>
        <Slide>
          <SlideText>
            <SliderTitle>Cerca quello di cui hai bisogno!</SliderTitle>
            <TextBlockSlider>
              Che tu abbia bisogno degli appunti di statistica, di un frullatore o di un paio di scarpe da corsa. Qui puoi trovarli.
            </TextBlockSlider>
          </SlideText>
          <SlideImage src="/path/to/image2.jpg" alt="Slide 2" />
        </Slide>
        <Slide>
          <SlideText>
            <SliderTitle>Cerca quello di cui hai bisogno!</SliderTitle>
            <TextBlockSlider>
              Che tu abbia bisogno degli appunti di statistica, di un frullatore o di un paio di scarpe da corsa. Qui puoi trovarli.
            </TextBlockSlider>
          </SlideText>
          <SlideImage src="/path/to/image3.jpg" alt="Slide 3" />
        </Slide>
      </CustomSlider>
    </PromotionsContainer>
  );
};

export default Promotions;
