import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from './api/axiosConfig';  // Usa l'istanza Axios configurata
import styled from 'styled-components';
import Chat from './Chat';

const Container = styled.div`
  display: flex;
  height: 80vh;
`;

const FiltersContainer = styled.div`
  width: 200px;
  padding: 20px;
  background-color: #f0f0f0;
  border-right: 1px solid #ccc;
`;

const ConversationsContainer = styled.div`
  width: 300px;
  padding: 20px;
  background-color: #fff;
  border-right: 1px solid #ccc;
  overflow-y: auto;
`;

const ChatContainer = styled.div`
  flex-grow: 1;
  padding: 20px;
  background-color: #f9f9f9;
`;

const FilterButton = styled.button`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: ${props => props.active ? '#800080' : '#fff'};
  color: ${props => props.active ? 'white' : 'black'};
  border: 1px solid #800080;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.active ? '#720072' : '#f0f0f0'};
  }
`;

const ConversationItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }

  p {
    margin: 5px 0;
  }
`;

const formatDate = (dateString) => {
    if (!dateString) return 'Data non disponibile';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Data non valida';
    return date.toLocaleString('it-IT', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
};

const TrashButton = styled(FilterButton)`
  background-color: ${props => props.active ? '#ff4d4d' : '#fff'};
  color: ${props => props.active ? 'white' : '#ff4d4d'};
  border-color: #ff4d4d;

  &:hover {
    background-color: ${props => props.active ? '#ff3333' : '#ffcccc'};
  }
`;

const ArchiveButton = styled(FilterButton)`
  background-color: ${props => props.active ? '#4CAF50' : '#fff'};
  color: ${props => props.active ? 'white' : '#4CAF50'};
  border-color: #4CAF50;

  &:hover {
    background-color: ${props => props.active ? '#45a049' : '#e8f5e9'};
  }
`;

const ActionButton = styled.button`
  padding: 5px 10px;
  margin-left: 10px;
  background-color: #800080;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #720072;
  }
`;

const ConfirmationPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  z-index: 1000;
`;

const PopupButton = styled.button`
  margin: 0 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
`;

const DirectMessages = ({ userId }) => {
    const [conversations, setConversations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [filter, setFilter] = useState('all');
    const [confirmDelete, setConfirmDelete] = useState(null);

    const fetchConversations = useCallback(async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            console.log('Fetching conversations with filter:', filter)
            const response = await axiosInstance.get(`/chat/messages/conversazioni/${userId}/?filter=${filter}`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });
            console.log('Dati grezzi ricevuti:', response.data);
            setConversations(response.data);
        } catch (err) {
            console.error('Errore durante il recupero delle conversazioni:', err.response?.data || err.message);
            setError('Errore durante il recupero delle conversazioni. Riprova più tardi.');
        } finally {
            setLoading(false);
        }
    }, [userId, filter]);

    useEffect(() => {
        fetchConversations();
    }, [fetchConversations]);

    useEffect(() => {
        console.log('Conversazioni ricevute:', conversations);
    }, [conversations]);

    const handleConversationClick = (conversationId) => {
        const selectedConv = conversations.find(conv => conv.id === conversationId);
        if (selectedConv && selectedConv.annuncio) {
            axiosInstance.get(`/annunci/${selectedConv.annuncio}/`)
                .then(response => {
                    const annuncioData = response.data;
                    setSelectedConversation({
                        ...selectedConv,
                        prezzo_annuncio: annuncioData.prezzo,
                        immagine_annuncio: annuncioData.immagine_principale || 'URL_immagine_default'
                    });
                })
                .catch(() => {
                    setSelectedConversation({
                        ...selectedConv,
                        prezzo_annuncio: 'Prezzo non disponibile',
                        immagine_annuncio: 'URL_immagine_default'
                    });
                });
        } else {
            setSelectedConversation(selectedConv);
        }
    };

    const handleMoveToTrash = async (conversationId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axiosInstance.post(`/chat/messages/conversazione/${conversationId}/trash/`, null, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            console.log('Operazione di spostamento nel cestino completata con successo:', response.data);
            alert(response.data.message);
            fetchConversations();  // Ricarica la lista delle conversazioni
        } catch (error) {
            console.error('Errore nello spostamento nel cestino:', error.response?.data || error.message);
            alert('Errore nello spostamento nel cestino: ' + (error.response?.data?.error || 'Si è verificato un errore'));
        }
    };

    const handleRestoreFromTrash = async (conversationId) => {
        try {
            await axiosInstance.post(`/chat/messages/conversazione/${conversationId}/restore/`);
            fetchConversations();
        } catch (error) {
            console.error('Errore nel ripristino dal cestino:', error);
        }
    };

    const handleArchiveToggle = async (conversationId, isCurrentlyArchived) => {
        try {
            await axiosInstance.post(`/chat/messages/conversazione/${conversationId}/archive/`);
            fetchConversations();
        } catch (error) {
            console.error(`Errore nell'${isCurrentlyArchived ? 'dearchiviazione' : 'archiviazione'}:`, error);
        }
    };

    const handleDeletePermanently = (conversationId) => {
        setConfirmDelete(conversationId);
    };

    const confirmDeletePermanently = async () => {
        try {
            await axiosInstance.delete(`/chat/messages/conversazione/${confirmDelete}/delete/`);
            fetchConversations();
            setConfirmDelete(null);
        } catch (error) {
            console.error('Errore nell\'eliminazione permanente:', error);
        }
    };

    const filteredConversations = conversations.filter(conversation => {
        console.log('Filtering conversation:', conversation); // Aggiungi questo log
        switch (filter) {
            case 'all':
                return !conversation.soft_deleted;
            case 'sent':
                return conversation.acquirente.toString() === userId.toString() && !conversation.soft_deleted && !conversation.is_archived;
            case 'received':
                return conversation.venditore.toString() === userId.toString() && !conversation.soft_deleted && !conversation.is_archived;
            case 'archived':
                return conversation.is_archived && !conversation.soft_deleted;
            case 'deleted':
                return conversation.soft_deleted === true;
            default:
                return true;
        }
    });

    const getOtherUserName = (conversation) => {
        if (!conversation) return 'Utente sconosciuto';
        if (conversation.acquirente.toString() === userId.toString()) {
            return conversation.venditore_username || `Venditore (ID: ${conversation.venditore})`;
        } else {
            return conversation.acquirente_username || `Acquirente (ID: ${conversation.acquirente})`;
        }
    };

    if (loading) {
        return <Container>Caricamento...</Container>;
    }

    if (error) {
        return <Container>{error}</Container>;
    }

    return (
        <Container>
            <FiltersContainer>
                <h3>Filtri</h3>
                <FilterButton active={filter === 'all'} onClick={() => setFilter('all')}>
                    Tutte
                </FilterButton>
                <FilterButton active={filter === 'sent'} onClick={() => setFilter('sent')}>
                    Inviate
                </FilterButton>
                <FilterButton active={filter === 'received'} onClick={() => setFilter('received')}>
                    Ricevuti
                </FilterButton>
                <ArchiveButton active={filter === 'archived'} onClick={() => setFilter('archived')}>
                    Archiviate
                </ArchiveButton>
                <TrashButton active={filter === 'deleted'} onClick={() => setFilter('deleted')}>
                    Cestino
                </TrashButton>
            </FiltersContainer>

            <ConversationsContainer>
                <h3>Conversazioni</h3>
                {filteredConversations.map(conversation => (
                    <ConversationItem
                        key={conversation.id}
                        onClick={() => handleConversationClick(conversation.id)}
                    >
                        <p>{conversation.titolo_annuncio}</p>
                        <p>{getOtherUserName(conversation)}</p>
                        <p>{conversation.ultimo_messaggio || 'Nessun messaggio'}</p>
                        <p>{formatDate(conversation.data_ultimo_messaggio)}</p>
                        {!conversation.soft_deleted && (
                            <>
                                <ActionButton onClick={(e) => { e.stopPropagation(); handleMoveToTrash(conversation.id); }}>
                                    Sposta nel cestino
                                </ActionButton>
                                <ActionButton onClick={(e) => { e.stopPropagation(); handleArchiveToggle(conversation.id, conversation.is_archived); }}>
                                    {conversation.is_archived ? 'Derarchivia' : 'Archivia'}
                                </ActionButton>
                            </>
                        )}
                        {conversation.soft_deleted && (
                            <>
                                <ActionButton onClick={(e) => { e.stopPropagation(); handleRestoreFromTrash(conversation.id); }}>
                                    Ripristina
                                </ActionButton>
                                <ActionButton onClick={(e) => { e.stopPropagation(); handleDeletePermanently(conversation.id); }}>
                                    Elimina definitivamente
                                </ActionButton>
                            </>
                        )}
                    </ConversationItem>
                ))}
            </ConversationsContainer>

            <ChatContainer>
                {selectedConversation ? (
                    <Chat
                        conversationId={selectedConversation.id}
                        onClose={() => setSelectedConversation(null)}
                        currentUserId={userId.toString()}
                        getOtherUsername={getOtherUserName}
                        initialConversationData={selectedConversation}
                    />
                ) : (
                    <p>Seleziona una conversazione per iniziare a chattare.</p>
                )}
            </ChatContainer>

            {confirmDelete && (
                <ConfirmationPopup>
                    <p>Sei sicuro di voler eliminare definitivamente questa conversazione?</p>
                    <PopupButton onClick={confirmDeletePermanently}>Conferma</PopupButton>
                    <PopupButton onClick={() => setConfirmDelete(null)}>Annulla</PopupButton>
                </ConfirmationPopup>
            )}
        </Container>
    );
};

export default DirectMessages;
