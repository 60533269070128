import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from './api/axiosConfig';  // Usa l'istanza Axios configurata
import CurrencyInput from 'react-currency-input-field';

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
`;

const CategoryInfo = styled.p`
  text-align: center;
  color: #555;
  font-size: 1.2rem;
  margin-top: 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #800080;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: center;

  &:hover {
    background-color: #9932cc;
  }
`;


const CategoriesContainer = styled.section`
  padding: 20px;
  background-color: #fff;
`;

const CategoriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;
  justify-items: center;
`;

const CategoryButton = styled.button`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 50px;
  padding: 20px;
  margin: 10px;
  width: 204px;
  height: 104px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  
  &:hover {
    background-color: #f0f0f0;
  }
`;

const CategoryIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
`;

const CategoryName = styled.span`
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
`;

const PreviewContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const PreviewItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PreviewImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const CheckboxLabel = styled.label`
  margin-top: 5px;
  font-size: 0.9rem;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

const Chip = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #800080;
  color: white;
  border-radius: 16px;
`;

const ChipText = styled.span`
  margin-right: 8px;
`;

const ChipCloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
`;

const SmallButton = styled.button`
  padding: 5px 10px;
  background-color: #800080;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 5px;
  font-size: 0.9rem;

  &:hover {
    background-color: #9932cc;
  }
`;

const PackageContainer = styled.div`
  margin-bottom: 20px;
`;

const PackageTitle = styled.h3`
  margin: 0;
  margin-bottom: 10px;
  color: #333;
`;

const PackageButton = styled(Button)`
  background-color: #800080;
`;

const CreateAd = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        titolo: '',
        descrizione: '',
        prezzo: '',
        categoria: '',
        categoria_personalizzata: '',
        condizioni: '',
        spedizione_digitale: false,
        keyword: '',
        img1: null,
        img2: null,
        img3: null,
        img4: null,
        img_main: '',
    });
    const [categories, setCategories] = useState([]);
    const [setMessages] = useState([]);
    const [step, setStep] = useState(1);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [annuncioId, setAnnuncioId] = useState(null);

    useEffect(() => {
        axiosInstance.get('/categories/')
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });
            
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        const val = type === 'checkbox' ? checked : (type === 'file' ? files : value);

        if (type === 'file') {
            const fileArray = Array.from(files).slice(0, 4).map(file => URL.createObjectURL(file));
            setImagePreviews(fileArray);

            const formImages = Array.from(files).slice(0, 4);
            setFormData({
                ...formData,
                img1: formImages[0] || null,
                img2: formImages[1] || null,
                img3: formImages[2] || null,
                img4: formImages[3] || null,
            });
        } else {
            setFormData({
                ...formData,
                [name]: val,
            });
        }
    };

    const handleImageMainChange = (imgName) => {
        setFormData({
            ...formData,
            img_main: imgName,
        });
    };

    const handleReplaceImage = (index) => {
        document.getElementById(`replace-image-${index}`).click();
    };

    const handleImageReplaceChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const newPreviews = [...imagePreviews];
            newPreviews[index] = URL.createObjectURL(file);
            setImagePreviews(newPreviews);

            setFormData({
                ...formData,
                [`img${index + 1}`]: file,
            });
        }
    };

    const handleRemoveImage = (index) => {
        const newPreviews = imagePreviews.filter((_, i) => i !== index);
        setImagePreviews(newPreviews);

        setFormData({
            ...formData,
            [`img${index + 1}`]: null,
        });
    };

    const handleKeywordKeyDown = (e) => {
        if ((e.key === 'Enter' || e.key === ',') && formData.keyword.trim() !== '' && keywords.length < 6) {
            e.preventDefault();
            const newKeyword = formData.keyword.trim().replace(/,$/, '');
            if (newKeyword && !keywords.includes(newKeyword)) {
                setKeywords([...keywords, newKeyword]);
                setFormData({ ...formData, keyword: '' });
            }
        }
    };

    const handleKeywordRemove = (keywordToRemove) => {
        setKeywords(keywords.filter(keyword => keyword !== keywordToRemove));
    };

    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (keywords.length === 0) {
            if (!window.confirm("Attenzione! Non hai inserito nessuna keywords. In questo modo diventerà più difficile trovare il tuo oggetto dagli altri utenti. Vuoi procedere comunque?")) {
                return;
            }
        }

        if (!formData.img_main) {
            alert('Seleziona un\'immagine principale');
            return;
        }

        setStep(3);
    };


    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handlePrevStep = () => {
        setStep(step - 1);
    };

    const handleCategorySelect = (category) => {
        setFormData({
            ...formData,
            categoria: category,
            categoria_personalizzata: category === 'Altra' ? '' : null,
        });
        handleNextStep();
    };

    const handleFinalSubmit = () => {
        const data = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key]) {
                data.append(key, formData[key]);
            }
        });
        // Aggiungi le keywords come stringa
        data.append('keyword', keywords.join(','));
        data.append('categoria', formData.categoria);  // Assicurati di includere la categoria
        const csrftoken = getCookie('csrftoken');

        axiosInstance.post(`/area_riservata/${userId}/crea_annuncio/`, data, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': csrftoken
            }
        })
            .then(response => {
                setAnnuncioId(response.data.id);  // Imposta l'ID dell'annuncio creato
                handleNextStep();  // Passa al passaggio successivo per l'acquisto del pacchetto premium
            })
            .catch(error => {
                if (error.response && error.response.data.messages) {
                    setMessages(error.response.data.messages);
                } else {
                    console.error('Error creating ad:', error);
                }
            });
    };


    const handlePremiumPurchase = (accept) => {
        if (accept) {
            const csrftoken = getCookie('csrftoken');
            axiosInstance.post(`/area_riservata/${userId}/crea_annuncio/invia_annuncio_approvazione/${annuncioId}/`, {
                acquisto_accettato: 'true'
            }, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRFToken': csrftoken
                }
            })
                .then(response => {
                    alert("Grazie! Il tuo annuncio è in fase di approvazione, se rispetterà le nostre linee guida sarà pubblicato.");
                    navigate(`/area_riservata/${userId}/crea_annuncio/invia_annuncio_approvazione/${annuncioId}/`);
                })
                .catch(error => {
                    console.error('Error purchasing premium package:', error);
                });
        } else {
            navigate(`/area_riservata/${userId}/crea_annuncio/invia_annuncio_approvazione/${annuncioId}/`);
        }
    };

    const handleProceedWithoutPremium = () => {
        const csrftoken = getCookie('csrftoken');
        axiosInstance.post(`/area_riservata/${userId}/crea_annuncio/invia_annuncio_approvazione/${annuncioId}/`, {
            acquisto_accettato: 'false'
        }, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': csrftoken
            }
        })
            .then(response => {
                alert("Grazie! Il tuo annuncio è in fase di approvazione, se rispetterà le nostre linee guida sarà pubblicato.");
                navigate(`/area_riservata/${userId}/crea_annuncio/invia_annuncio_approvazione/${annuncioId}/`);
            })
            .catch(error => {
                console.error('Error sending ad for approval:', error);
            });
    };

    // Aggiungi questa nuova funzione per gestire il cambio del prezzo
    const handlePriceChange = (value, name) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <Container>
            {step === 1 && (
                <>
                    <Title>Seleziona una categoria</Title>
                    <CategoriesContainer>
                        <CategoriesGrid>
                            {categories.map((cat) => (
                                <CategoryButton key={cat.name} onClick={() => handleCategorySelect(cat.name)}>
                                    <CategoryIcon src={cat.icon_url} alt={cat.name} />
                                    <CategoryName>{cat.name}</CategoryName>
                                </CategoryButton>
                            ))}
                        </CategoriesGrid>
                    </CategoriesContainer>
                </>
            )}
            {step === 2 && (
                <>
                    <Title>Inserisci i dati dell'annuncio</Title>
                    <CategoryInfo>Categoria selezionata: {formData.categoria}</CategoryInfo>
                    <Form onSubmit={handleSubmit} encType="multipart/form-data">
                        <label>
                            Titolo:
                            <input
                                type="text"
                                name="titolo"
                                value={formData.titolo}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Descrizione:
                            <textarea
                                name="descrizione"
                                value={formData.descrizione}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Prezzo (€):
                            <CurrencyInput
                                name="prezzo"
                                value={formData.prezzo}
                                decimalsLimit={2}
                                decimalSeparator=","
                                groupSeparator="."
                                onValueChange={(value, name) => handlePriceChange(value, name)}
                                prefix="€ "
                                placeholder="€ 0,00"
                            />
                        </label>
                        <label>
                            Condizioni:
                            <select
                                name="condizioni"
                                value={formData.condizioni}
                                onChange={handleChange}
                            >
                                <option value="">Seleziona una condizione</option>
                                <option value="Usato">Usato</option>
                                <option value="Come nuovo">Come nuovo</option>
                                <option value="Nuovo">Nuovo</option>
                            </select>
                        </label>
                        <label>
                            Spedizione digitale:
                            <input
                                type="checkbox"
                                name="spedizione_digitale"
                                checked={formData.spedizione_digitale}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Keywords:
                            <input
                                type="text"
                                name="keyword"
                                value={formData.keyword}
                                onChange={handleChange}
                                onKeyDown={handleKeywordKeyDown}
                                placeholder="Inserisci fino a 6 keywords separate da virgole"
                                disabled={keywords.length >= 6}
                            />
                            <ChipContainer>
                                {keywords.map((keyword, index) => (
                                    <Chip key={index}>
                                        <ChipText>{keyword}</ChipText>
                                        <ChipCloseButton onClick={() => handleKeywordRemove(keyword)}>&times;</ChipCloseButton>
                                    </Chip>
                                ))}
                            </ChipContainer>
                        </label>
                        <input type="hidden" name="keywords" value={keywords.join(',')} /> {/* Campo nascosto per keywords */}
                        <label>
                            Carica immagini (fino a 4):
                            <input
                                type="file"
                                name="images"
                                onChange={handleChange}
                                accept="image/*"
                                multiple
                            />
                            <PreviewContainer>
                                {imagePreviews.map((src, index) => (
                                    <PreviewItem key={index}>
                                        <PreviewImage src={src} alt={`Preview ${index + 1}`} />
                                        <CheckboxLabel>
                                            <input
                                                type="checkbox"
                                                name="img_main"
                                                checked={formData.img_main === `img${index + 1}`}
                                                onChange={() => handleImageMainChange(`img${index + 1}`)}
                                            />
                                            Immagine principale
                                        </CheckboxLabel>
                                        <SmallButton onClick={() => handleReplaceImage(index)}>Sostituisci</SmallButton>
                                        <input
                                            type="file"
                                            id={`replace-image-${index}`}
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleImageReplaceChange(e, index)}
                                        />
                                        <SmallButton onClick={() => handleRemoveImage(index)}>Rimuovi</SmallButton>
                                    </PreviewItem>
                                ))}
                            </PreviewContainer>
                        </label>
                        <Button type="button" onClick={handlePrevStep}>Indietro</Button>
                        <Button type="submit">Riepilogo Annuncio</Button>
                    </Form>
                </>
            )}
            {step === 3 && (
                <>
                    <Title>Riepilogo Annuncio</Title>
                    <div>
                        <p><strong>Titolo:</strong> {formData.titolo}</p>
                        <p><strong>Descrizione:</strong> {formData.descrizione}</p>
                        <p><strong>Prezzo:</strong> {formData.prezzo} €</p>
                        <p><strong>Categoria:</strong> {formData.categoria}</p>
                        {formData.categoria === 'Altra' && (
                            <p><strong>Categoria personalizzata:</strong> {formData.categoria_personalizzata}</p>
                        )}
                        <p><strong>Condizioni:</strong> {formData.condizioni}</p>
                        <p><strong>Spedizione digitale:</strong> {formData.spedizione_digitale ? 'Sì' : 'No'}</p>
                        <p><strong>Keywords:</strong> {keywords.join(', ')}</p>
                        <p><strong>Immagine principale:</strong> {formData.img_main}</p>
                        <PreviewContainer>
                            {imagePreviews.map((src, index) => (
                                <PreviewItem key={index}>
                                    <PreviewImage src={src} alt={`Preview ${index + 1}`} />
                                </PreviewItem>
                            ))}
                        </PreviewContainer>
                    </div>
                    <Button type="button" onClick={handlePrevStep}>Indietro</Button>
                    <Button type="button" onClick={handleFinalSubmit}>Invia Annuncio</Button>
                </>
            )}
            {step === 4 && (
                <>
                    <Title>Acquista Pacchetto Premium</Title>
                    <PackageContainer>
                        <PackageTitle>Pacchetto Bronzo</PackageTitle>
                        <PackageButton onClick={() => handlePremiumPurchase('Bronzo')}>Acquista</PackageButton>
                    </PackageContainer>
                    <PackageContainer>
                        <PackageTitle>Pacchetto Argento</PackageTitle>
                        <PackageButton onClick={() => handlePremiumPurchase('Argento')}>Acquista</PackageButton>
                    </PackageContainer>
                    <PackageContainer>
                        <PackageTitle>Pacchetto Oro</PackageTitle>
                        <PackageButton onClick={() => handlePremiumPurchase('Oro')}>Acquista</PackageButton>
                    </PackageContainer>
                    <Button type="button" onClick={handleProceedWithoutPremium}>Procedi senza acquistare</Button>
                </>
            )}
        </Container>
    );
};

export default CreateAd;
