import React from 'react';
import styled from 'styled-components';

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

const Slide2 = ({ formData, handleChange, nextStep, prevStep }) => {
    return (
        <SlideContainer>
            <Title>Per migliorare l'esperienza dell'applicazione...</Title>
            <Input
                type="text"
                name="via"
                placeholder="Via"
                value={formData.via}
                onChange={handleChange('via')}
            />
            <Input
                type="text"
                name="numero_civico"
                placeholder="Numero Civico"
                value={formData.numero_civico}
                onChange={handleChange('numero_civico')}
            />
            <Input
                type="text"
                name="cap"
                placeholder="CAP"
                value={formData.cap}
                onChange={handleChange('cap')}
            />
            <Input
                type="text"
                name="citta"
                placeholder="Città"
                value={formData.citta}
                onChange={handleChange('citta')}
            />
            <Input
                type="text"
                name="provincia"
                placeholder="Provincia"
                value={formData.provincia}
                onChange={handleChange('provincia')}
            />
            <Button onClick={prevStep}>Indietro</Button>
            <Button onClick={nextStep}>Avanti</Button>
        </SlideContainer>
    );
};

export default Slide2;
