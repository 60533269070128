import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from './api/axiosConfig';
import styled from 'styled-components';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ChatPopup from './ChatPopup';
import { AuthContext } from './App';

const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const BackLink = styled(Link)`
  display: block;
  margin-bottom: 20px;
  font-size: 1rem;
  color: #800080;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Title = styled.h1`
  color: #800080;
  font-size: 2rem;
  margin-bottom: 20px;
`;

const AnnuncioDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ImageGallery = styled.div`
  flex: 1;
  margin-right: 20px;

  img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .main-photo {
    border: 2px solid #800080;
  }
`;

const AnnuncioInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Price = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #69A6F7;
`;

const Description = styled.p`
  margin: 10px 0;
`;

const Condition = styled.p`
  margin: 10px 0;
`;

const Seller = styled.p`
  margin: 10px 0;
`;

const CTAButtons = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin: 5px 0;
  }

  .disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const InfoSection = styled.section`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  div {
    flex: 1;
    text-align: center;
    padding: 10px;
    background-color: #f8f8f8;
    margin: 0 10px;
    border-radius: 5px;
  }
`;

const RelatedSection = styled.section`
  margin: 20px 0;
`;

const AnnuncioCard = styled.div`
  display: inline-block;
  width: 200px;
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;

  img {
    width: 100%;
    height: auto;
  }

  h3 {
    font-size: 1rem;
    margin: 10px 0;
  }

  p {
    margin: 10px 0;
    font-weight: bold;
  }
`;

const LoginAlert = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  p {
    margin-bottom: 10px;
  }

  button {
    margin: 5px;
  }
`;

const Annuncio = ({ fromAreaRiservata }) => {
  const { id } = useParams();
  const { isUserLoggedIn, userId } = useContext(AuthContext);
  const navigate = useNavigate();
  const [annuncio, setAnnuncio] = useState(null);
  const [relatedAnnunci, setRelatedAnnunci] = useState([]);
  const [popularAnnunci, setPopularAnnunci] = useState([]);
  const [isOwner, setIsOwner] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showLoginAlert, setShowLoginAlert] = useState(false);

  useEffect(() => {
    // Converti userId a numero subito
    const userIdAsNumber = Number(userId);

    if (userIdAsNumber) {
      const fetchAnnuncio = async () => {
        console.log("Fetching annuncio data...");
        try {
          // Usa l'istanza di Axios configurata
          const response = await axiosInstance.get(`/annuncio/elenco_annunci/${id}/`);
          const data = response.data;
          if (data) {
            const isOwnerResult = data.user_id === userIdAsNumber;
            setIsOwner(isOwnerResult);
            setAnnuncio(data);
            setRelatedAnnunci(data.related_annunci || []);
            setPopularAnnunci(data.popular_annunci || []);
            setIsFollowing(data.is_following || false);
          } else {
            console.error('No data received for the annuncio');
          }
        } catch (error) {
          console.error('Error fetching the annuncio data', error);
        }
      };

      fetchAnnuncio();
    }
  }, [id, userId]);


 // Assicurati che useEffect dipenda da userId

  const handleActionClick = async (action) => {
    if (!isUserLoggedIn) {
      setShowLoginAlert(true);
    } else {
      if (action === 'contatta') {
        setShowPopup(true);
      } else if (action === 'segui') {
        try {
          const endpoint = isFollowing ? `/annuncio/smetti_di_seguire/${annuncio.id}/` : `/annuncio/segui/${annuncio.id}/`;
          const response = await axiosInstance.post(endpoint);
          if (response.status === 200) {
            setIsFollowing(!isFollowing);
          }
        } catch (error) {
          console.error('Errore nel gestire il follow dell\'annuncio:', error);
        }
      }
    }
  };

  if (!annuncio) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      {fromAreaRiservata && (
        <BackLink to={`/api/utenti/area_riservata/${annuncio.user_id}`}>
          Torna alla mia Area Riservata
        </BackLink>
      )}
      <Title>{annuncio.titolo || 'Titolo non disponibile'}</Title>
      <AnnuncioDetails>
        <ImageGallery>
          {annuncio.img1 && <img src={annuncio.img1} alt="Immagine 1" className={annuncio.img_main === 'img1' ? 'main-photo' : ''} />}
          {annuncio.img2 && <img src={annuncio.img2} alt="Immagine 2" className={annuncio.img_main === 'img2' ? 'main-photo' : ''} />}
          {annuncio.img3 && <img src={annuncio.img3} alt="Immagine 3" className={annuncio.img_main === 'img3' ? 'main-photo' : ''} />}
          {annuncio.img4 && <img src={annuncio.img4} alt="Immagine 4" className={annuncio.img_main === 'img4' ? 'main-photo' : ''} />}
        </ImageGallery>
        <AnnuncioInfo>
          <Price>€{annuncio.prezzo || 'N/A'}</Price>
          <Description>{annuncio.descrizione || 'Descrizione non disponibile'}</Description>
          <Condition>Condizioni: {annuncio.condizioni || 'N/A'}</Condition>
          <Condition>Spedizione Digitale: {annuncio.spedizione_digitale ? 'Si' : 'No'}</Condition>
          <Seller>Venditore: {annuncio.user_username || 'Username non disponibile'}</Seller>
          <CTAButtons>
            {isOwner ? (
              <>
                <p>Visualizzazioni: {annuncio.views || 0}</p>
                <p>Follows: {annuncio.follows || 0}</p>
                <button onClick={() => window.location.href = `/modifica_annuncio/${annuncio.id}`}>Modifica Annuncio</button>
              </>
            ) : (
              <>
                <button onClick={() => handleActionClick('contatta')}>Contatta il venditore</button>
                <button onClick={() => handleActionClick('segui')}>
                  {isFollowing ? 'Smetti di seguire' : 'Segui Annuncio'}
                </button>
              </>
            )}
          </CTAButtons>
        </AnnuncioInfo>
      </AnnuncioDetails>
      <InfoSection>
        <div>
          <h3>Spediamo in tutta Italia</h3>
          <p>Spediamo il tuo articolo in 3 giorni lavorativi</p>
        </div>
        <div>
          <h3>Pagamenti Sicuri</h3>
          <p>Utilizziamo solamente i servizi di pagamento più affidabili</p>
        </div>
        <div>
          <h3>Reso del prodotto</h3>
          <p>Dove compare questa icona, significa che potrai restituire l’articolo</p>
        </div>
      </InfoSection>
      <RelatedSection>
        <h2>Potrebbero anche interessarti</h2>
        <div>
          {relatedAnnunci.length > 0 ? (
            relatedAnnunci.map(related => (
              <AnnuncioCard key={related.id}>
                {related.img_main && <img src={related.img_main} alt={related.titolo} />}
                <h3>{related.titolo}</h3>
                <p>€{related.prezzo}</p>
                <Link to={`/annuncio/${related.id}`}>Visualizza</Link>
              </AnnuncioCard>
            ))
          ) : (
            <p>Nessun annuncio correlato disponibile</p>
          )}
        </div>
      </RelatedSection>
      <RelatedSection>
        <h2>Gli articoli più cercati</h2>
        <div>
          {popularAnnunci.length > 0 ? (
            popularAnnunci.map(popular => (
              <AnnuncioCard key={popular.id}>
                {popular.img_main && <img src={popular.img_main} alt={popular.titolo} />}
                <h3>{popular.titolo}</h3>
                <p>€{popular.prezzo}</p>
                <Link to={`/annuncio/${popular.id}`}>Visualizza</Link>
              </AnnuncioCard>
            ))
          ) : (
            <p>Nessun articolo popolare disponibile</p>
          )}
        </div>
      </RelatedSection>
      {showPopup && (
        <ChatPopup
          onClose={() => setShowPopup(false)}
          annuncioId={annuncio.id}
          userId={userId}
        />
      )}
      {showLoginAlert && (
        <LoginAlert>
          <p>Per contattare il venditore o seguire l'annuncio, devi effettuare il login o registrarti al portale.</p>
          <button onClick={() => navigate('/api/login')}>Accedi</button>
          <button onClick={() => navigate('/registrazione')}>Registrati</button>
          <button onClick={() => setShowLoginAlert(false)}>Chiudi</button>
        </LoginAlert>
      )}
    </Container>
  );
};

export default Annuncio;
