import React, { useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from './api/axiosConfig';  // Usa l'istanza Axios configurata
import { AuthContext } from './App';

const ConfirmUser = () => {
    const { uidb64, token } = useParams();
    const navigate = useNavigate();
    const { handleLoginSuccess } = useContext(AuthContext);

    useEffect(() => {
        console.log('ConfirmUser component mounted');
        const confirmUser = async () => {
            try {
                console.log(`Requesting confirmation for uidb64: ${uidb64} and token: ${token}`);
                const response = await axiosInstance.get(`/confirm_user/${uidb64}/${token}/`);
                console.log('Response from confirm_user:', response.data);
                if (response.data.status === 'success') {
                    const { user_id, username, avatar, token } = response.data;
                    console.log('User confirmed:', user_id, username, avatar, token);
                    handleLoginSuccess(user_id, username, avatar, token);
                    console.log('Navigating to onboarding');
                    navigate(`/onboarding/${uidb64}/${token}/`);
                } else {
                    console.error('Error confirming user:', response.data.message);
                    navigate('/');
                }
            } catch (error) {
                console.error('Error confirming user:', error);
                navigate('/');
            }
        };

        confirmUser();
    }, [uidb64, token, handleLoginSuccess, navigate]);

    return <div>Confirming your account...</div>;
};

export default ConfirmUser;
