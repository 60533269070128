import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axiosInstance from './api/axiosConfig';  // Usa l'istanza Axios configurata
import { useLocation, Link } from 'react-router-dom';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f6f6f6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
`;

const CloseButton = styled.button`
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  margin-bottom: 10px;
  color: black;
`;

const LoginHeader = styled.div`
  text-align: center;
  margin-bottom: 20px;
  color: black;
`;

const PlaceholderImage = styled.div`
  width: 100px;
  height: 100px;
  background-color: gray;
  border-radius: 50%;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

const RegisterLink = styled.p`
  margin-top: 20px;
  text-align: center;
  
  a {
    color: black;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin: 10px 0;
`;

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function Login({ onClose, onLoginSuccess }) {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const uidb64 = params.get('uidb64');
    const token = params.get('token');

    if (uidb64 && token) {
      localStorage.setItem('uidb64', uidb64);
      localStorage.setItem('token', token);
    }
  }, [location.search]);

  const handleLogin = async (event) => {
    event.preventDefault();
    const csrftoken = getCookie('csrftoken');
    try {
      const response = await axiosInstance.post(
        '/login/',
        {
          username_or_email: usernameOrEmail,
          password: password,
        },
        {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': csrftoken,
          },
          withCredentials: true,
        }
      );
      if (response.data.status === 'success') {
        const { user_id, username, avatar, onboarding_completato, uidb64, token } = response.data;
        console.log('Login response:', response.data); // Log della risposta

        // Salva uidb64 e token nel localStorage
        localStorage.setItem('uidb64', uidb64);
        localStorage.setItem('token', token);

        // Aggiorna il token CSRF se il server lo fornisce nel login response
        const csrftoken = document.cookie.split('; ').find(row => row.startsWith('csrftoken'));
        if (csrftoken) {
          const token = csrftoken.split('=')[1];
          axiosInstance.defaults.headers.common['X-CSRFToken'] = token;
        } else {
          console.error('CSRF token not found!');
        }
        
        // Imposta il CSRF token nell'istanza Axios
        axiosInstance.defaults.headers.common['X-CSRFToken'] = csrftoken;

        onLoginSuccess(user_id, username, avatar, token, onboarding_completato, uidb64);

        // Redirect to the homepage after successful login
        window.location.href = '/';
      } else {
        setError('Credenziali non valide. Riprova.');
      }
    } catch (error) {
      setError('Errore durante il login. Riprova più tardi.');
      console.error('Login error', error);
    }
  };

  return (
    <LoginContainer>
      <CloseButton onClick={onClose} aria-label="Close">
        ✕
      </CloseButton>
      <LoginHeader>
        <PlaceholderImage />
        <h1>Perstudenti.com</h1>
        <h2>Login</h2>
      </LoginHeader>
      <form onSubmit={handleLogin}>
        <Input
          type="text"
          placeholder="Username or Email address"
          value={usernameOrEmail}
          onChange={(e) => setUsernameOrEmail(e.target.value)}
          required
          aria-label="Username or Email address"
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          aria-label="Password"
        />
        {error && <ErrorMessage aria-live="polite">{error}</ErrorMessage>}
        <Button type="submit">Entra</Button>
      </form>
      <RegisterLink>
        Nuovo utente? <Link to="/registrazione">Registrati ora</Link>
      </RegisterLink>
    </LoginContainer>
  );
}

export default Login;
