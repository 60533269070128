import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  display: ${(props) => (props.horizontal ? 'flex' : 'inline-block')};
  width: ${(props) => (props.horizontal ? '100%' : '200px')};
  margin: ${(props) => (props.horizontal ? '10px 0' : '10px')};
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
`;

const ImageContainer = styled.div`
  width: ${(props) => (props.horizontal ? '100px' : '100%')};
  height: ${(props) => (props.horizontal ? '100px' : 'auto')};
  flex-shrink: 0;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.horizontal ? 'center' : 'flex-start')};
`;

const Title = styled.h3`
  font-size: 1rem;
  margin: 0 0 5px 0;
`;

const Price = styled.p`
  margin: 0;
  font-weight: bold;
`;

const Card = ({ image, title, price, onClick, horizontal = false }) => {
  return (
    <CardContainer as="div" onClick={onClick} horizontal={horizontal.toString()}>
      <ImageContainer horizontal={horizontal}>
        <Image src={image} alt={title} />
      </ImageContainer>
      <ContentContainer horizontal={horizontal}>
        <Title>{title}</Title>
        <Price>
          {typeof price === 'number'
            ? `€${price.toFixed(2)}`
            : (price || 'Prezzo non disponibile')}
        </Price>
      </ContentContainer>
    </CardContainer>
  );
};

export default Card;
